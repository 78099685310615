<template>
    <div>
        <div
            class="d-flex"
            :class="$store.getters.currentUser.id == messageObject.sender_id ? 'justify-content-end pr-4' : 'justify-content-start pl-4'"
        >
            <div
                class="d-flex flex-column original-message"
                :class="$store.getters.currentUser.id == messageObject.sender_id ? 'align-items-end' : 'align-items-start'"
                v-if="messageObject.response_to"
            >
                <div class="d-flex align-self-start" v-if="name !== 'Général'">
                    <i class="fas fa-share fa-flip-horizontal mr-2"></i>
                    <p class="m-0" style="font-size: 11px">
                        {{
                            $store.getters.currentUser.id == messageObject.sender_id
                                ? 'Vous avez répondu à '.name
                                : name + ' vous à répondu'
                        }}
                    </p>
                </div>
                <div class="message-box grey">
                    <div class="message-content">
                        <div class="message-text">
                            {{ messageObject.response_to }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="d-flex"
            :class="$store.getters.currentUser.id == messageObject.sender_id ? 'justify-content-end pr-4' : 'justify-content-start pl-4'"
        >
            <div
                class="message-box"
                :class="{
                    blue: $store.getters.currentUser.id === messageObject.sender_id,
                    green: $store.getters.currentUser.id !== messageObject.sender_id,
                    response: messageObject.response_to
                }"
            >
                <div class="message-content">
                    <div class="message-text">
                        {{ messageObject.message }}
                    </div>
                    <div class="message-time pt-2 ml-5">
                        {{ getDate(messageObject.created_at) }}
                    </div>
                </div>
            </div>
            <i
                id="answerButton"
                class="fas fa-share ml-2 align-self-start"
                v-if="$store.getters.currentUser.id !== messageObject.sender_id && tuteur"
                @click="$emit('answerMessage', messageObject)"
                v-b-tooltip.hover.top="'Citer le message dans le chat général'"
            ></i>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Message',
    props: {
        messageObject: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            required: false
        },
        tuteur: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        getDate(MessageDate) {
            //si MessageDate est aujourd'hui, on affiche l'heure sans les secondes
            //si MessageDate est hier, on affiche "hier"
            //si MessageDate est dans la semaine, on affiche le jour de la semaine abrégé avec une majuscule
            //sinon on affiche la date
            const date = new Date(MessageDate);
            const today = new Date();
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            const lastWeek = new Date();
            lastWeek.setDate(lastWeek.getDate() - 7);
            if (date.toDateString() === today.toDateString()) {
                return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
            } else if (date.toDateString() === yesterday.toDateString()) {
                return 'Hier';
            } else if (date > lastWeek) {
                let formatDate = date.toLocaleDateString('fr-FR', { weekday: 'short' });
                return formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
            } else {
                return date.toLocaleDateString('fr-FR');
            }
        }
    }
};
</script>
<style>
#answerButton:hover {
    cursor: pointer;
    color: #1e1e1e;
}
.message-box {
    display: flex;
    margin-bottom: 10px;
    width: fit-content;
    max-width: 70%;
    border-radius: 10px;
}
.message-content {
    border-radius: 10px;
    padding: 10px 15px;
}
.message-text {
    word-break: break-all;
    color: #000;
}
.message-time {
    font-size: 10px;
    color: #000;
    text-align: right;
}
.blue {
    background-color: #a0cbf8;
    justify-content: flex-end;
}
.green {
    background-color: #a3e9b3;
    justify-content: flex-start;
}
.original-message {
    max-width: 80%;
}
.grey {
    background-color: #acabab;
    font-size: 11px;
    justify-content: flex-end;
    margin-bottom: 0;
    width: 100%;
    z-index: -1;
}
.grey .message-text {
    color: #eeecec;
}

.response {
    margin-top: -0.6rem;
}

.message-box.grey {
    max-width: 100%;
    width: auto;
}
</style>
